import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/envelope.jpg'
class ContactTemplate extends React.Component{
  render(){
    return(
      <Layout>
      <SEO title="Contact Us" />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">Contact</h1>
            <h6 className="subtitle">We're here to help and answer any question you might have. We look forward to hearing from you.</h6>
        </div>
      </section>
      
      <section className="section contact-page">
          <div className="container">
        <div className="contact-infos">
            <div className="single">
                <i className="address"></i>
                <a href="https://www.google.com/maps/place/4601+Fairfax+Dr+Suite+1200,+Arlington,+VA+22203,+USA/@38.8826504,-77.1202361,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b43a48957723:0x32bc4ba11224b321!8m2!3d38.8826504!4d-77.1180474" target="_blank">4601 N. Fairfax Dr. Suite 1200 Arlington VA, 22203</a>
          

           
                <i className="phone"></i>
                <a href="tel:571-483-8440" target="_blank">571-483-8440</a>
            </div>
      

            <form className="contact-form default-form" name="contact" method="POST" action="/thank-you" data-netlify="true" data-netlify-honeypot="bot-field">
                <span>Fields marked with (*) are required.</span>
                <fieldset>
                <input type="hidden" name="form-name" value="contact" />
                    <div className="columns is-multiline">
                        <label className="column is-6" htmlFor="name">
                            <input type="text" name="name" placeholder="First name *" required/>
                        </label>
                        <label className="column is-6" htmlFor="surname">
                            <input type="text" name="surname" placeholder="Last name *" required/>
                        </label>
                        <label className="column is-4" htmlFor="email">
                            <input type="email" name="email" placeholder="Email address *" required/>
                        </label>

                        <label className="column is-4" htmlFor="company">
                            <input type="text" name="company" placeholder="Company name" />
                        </label>

                        <label className="column is-4" htmlFor="postal_code">
                            <input type="text" name="postal_code" placeholder="Postal code" />
                        </label>
                        <label className="column is-12" htmlFor="message">
                            <textarea name="message" placeholder="How can we help you? *" required></textarea>
                        </label>

                        <div className="send-message-box column is-12">
                            <label htmlFor="privacy_policy" className="checkbox">I understand and agree to the <Link to="/privacy-policy">Privacy Policy</Link>.
                            <input type="checkbox" className="checkbox-input" name="privacy_policy" required />
                            <span class="checkmark"></span>
                            </label>
                            <button className="button gold">Send Message</button>
                        </div>
                    </div>
                </fieldset>
                    </form>
                    </div>     
          </div>
      </section>
      </Layout>
    )
  }
}

export default ContactTemplate
